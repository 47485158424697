import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'

const firebaseConfig = {
    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID,
    // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

    apiKey: "AIzaSyBdtdXWMa-Jw9aRcP58ZcObc93kLf6gf-o",
    authDomain: "dopleycolors-5a6c5.firebaseapp.com",
    databaseURL: "https://dopleycolors-5a6c5-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "dopleycolors-5a6c5",
    storageBucket: "dopleycolors-5a6c5.appspot.com",
    messagingSenderId: "1048626260576",
    appId: "1:1048626260576:web:798f53ad8040f0d76ab105",
    measurementId: "G-71SWMMK2TJ"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics()

const db = firebase.firestore()
console.log("===============",db.collection);
export default db