import React from 'react'
import './Info.css'
import LogoImg from '../../img/logo.png'

const Info = () => {
    return (
        <div className="Info">

            <img className="Logo" src={LogoImg} alt=""/>

            <h1 className="Info__Head">Hope you are loving Dopley Colors</h1>
            <p className="Info__Desc Info__Desc--main">Dopley Colors is a new color palettes website, the ultimate destination for all your color inspiration needs.
            Whether you're a designer, artist, or just looking to add a pop of color to your home, we've got you covered <br />
            Just browse some palettes and make your project Awesome.
            </p>

            <h1 className="Info__Head">How to use the app</h1>
            <p className="Info__Desc">
                Browse and search for a palette<br />
                Hover over the palette for color code<br />
                Click on colors to copy color code<br />
            </p>

            <h1 className="Info__Head">Meet the team</h1>
            <p className="Info__Desc" style={{marginBottom: ".5rem"}}>Teamuiux</p>
            <span className="Info__Links">
                <a
                    className="Links-Item"
                    href="https://www.facebook.com/teamuiux/"
                    target="_blank"
                    rel="noopener noreferrer"
                >Facebook</a>
                <a
                    className="Links-Item"
                    href="https://www.instagram.com/teamuiux"
                    target="_blank"
                    rel="noopener noreferrer"
                >Instagram</a>
            </span>

            <h1 className="Info__Head">&copy; Dopley Colors 2023</h1>
            <p className="Info__Desc">
                
            </p>
        </div>
    )
}

export default Info
